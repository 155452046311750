import { FormattedMessage } from "react-intl";
import Container from "../components/atoms/Container";
import Layout from "../components/organisms/Layout";

function NotFound() {
  return (
    <Layout title={<FormattedMessage defaultMessage="404" />}>
      <Container>
        <FormattedMessage id="errors.pageDoesNotExist" />
      </Container>
    </Layout>
  );
}

export default NotFound;
